import Add from "./Add"
import * as React from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Login from "./Login";
import Cart from "./Cart";

const List = React.lazy(() => import('./List'));
const Edit = React.lazy(() => import('./Edit'));

const App = () => {

    return (
            <BrowserRouter>            
                <div>                    
                    <Routes>                    
                    <Route path="" element={<Login />} />
                    <Route path="list" element={
                        <React.Suspense fallback={<center><br /><br />Loading . . .</center>} >
                            <List />
                        </React.Suspense>
                    } />
                    <Route path="add" element={<Add />} />
                    <Route path="cart" element={<Cart />} />
                    <Route path="edit/:user_id" element={
                        <React.Suspense fallback={<center><br/><br/>Loading . . .</center>} >
                            <Edit />
                        </React.Suspense>
                    } />
                    </Routes>
                </div>                
            </BrowserRouter>
    )

}

export default App;