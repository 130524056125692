import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './Style.css'

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import cartReducer from './features/cart_reducer'

//export const API_BASE_URL = "http://localhost:85/php_api/";
export const API_BASE_URL = "http://dev.purebeautyindia.in/php_api/";

const store = configureStore ({
  reducer: {
    cart: cartReducer,
  }
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>    
  </React.StrictMode>
);