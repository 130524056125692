import axios from "axios";
import { useState, useEffect } from "react";
import { API_BASE_URL } from ".";
import { useNavigate } from "react-router";
import { getUserSession } from './Storage';
import Logout from "./Logout";

const Add = () => {

    const initialState = {"Name": "", "Description": "", "Price": ""};

    const [user, setUser] = useState(initialState);
    const [file, setFile] = useState([]);

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setUser({...user, [name]: value});
    }

    const submitForm = (event) => {
        event.preventDefault();

        if(user.Name != "" && user.Description != "" && user.Price != "") { 

            document.getElementById('submit_btn').innerText = "Submiting ...";

            const formData = new FormData();
            formData.append("Name", user.Name);
            formData.append("Description", user.Description);
            formData.append("Price", user.Price);
            formData.append("Item_Image", file);
            
            axios.post(API_BASE_URL+"create.php", formData).then((response) => {            
                if(response.data == "success") {
                    navigate("/list");
                } else {
                    alert(response.data);
                }
            }).catch((response) => {
                alert(response);
            }) 
        } else {
            alert("Invalid Inputs")
        }

               
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    }
    
    useEffect ( () => {
        if(getUserSession("user_id") == null) {
            navigate("/");
        }
    }, [])

    return (
        <div>
        <center>
        <form onSubmit={submitForm}>
                <h1>Add Item</h1>
                <table>
                    <tbody>
                        <tr><td>Name:</td><td><input type="text"  onChange={handleInputChange} value={user.Name} name="Name" id="Name" /></td></tr>
                        <tr><td>Description:</td><td><input type="text"  onChange={handleInputChange} value={user.Description} name="Description" id="Description" /></td></tr>
                        <tr><td>Price:</td><td><input type="text"  onChange={handleInputChange} value={user.Price} name="Price" id="Price" /></td></tr>
                        <tr><td>Item Image:</td><td><input type="file" accept="image/*"  onChange={handleFileChange} name="Item_Image" id="Item_Image" /></td></tr>
                        <tr><td align="center" colSpan={2}><button id="submit_btn" className="button button1">Submit</button></td></tr>
                    </tbody>                
                </table>
            </form>
            <Logout />
        </center>            
        </div>
        
    )
}

export default Add;