import { useNavigate } from "react-router";
import { clearUserSession, getUserSession } from "./Storage";
import { useEffect } from "react";

const Logout = () => {

        const navigate = useNavigate();

        const logout = () => {
            clearUserSession();
            navigate("/")
        }

        const back = () => {
            navigate("/list");
        }

    return (
    <div>
        <br /><br />
        <button className='button button4' id="logout_btn" onClick={back}>Home</button>
        <button className='button button3' id="logout_btn" onClick={logout}>Logout</button>
    </div>
    
    )
}

export default Logout;