import axios from "axios";
import { useState } from "react";
import { API_BASE_URL } from ".";
import { setUserId, setUserSession } from "./Storage";
import { useNavigate } from "react-router";
const Login = () => {

    const initialState = {"Username": "user1", "Password": "user1"}
    const [Login, setLogin] = useState(initialState);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        setLogin({...Login, [name]: value})
    }

    const navigate = useNavigate();

    const submitForm = (event) => {
        event.preventDefault();
        document.getElementById('submit_btn').innerText = "Submiting ...";
        axios.post(API_BASE_URL+"login.php", Login).then((response) => {
            if(response.data.response == "success") {
                setUserSession(response.data.user_id);
                navigate("list");
            } else {
                setUserSession(null);
                alert(response.data.response)
            }
        }).catch((response) => {
            alert(response);
        });
    }

    return (

        <center>
            <h1>Simple React CRUD Operations</h1>
            <form onSubmit={submitForm}>
                <table>
                    <tr><td>Username</td><td><input  type="text" value={Login.Username} name="Username" onChange={handleInputChange} /></td></tr>
                    <tr><td>Password</td><td><input  type="password" value={Login.Password} name="Password" onChange={handleInputChange} /></td></tr>
                    <tr><td align="center" colSpan={2}><button id="submit_btn" className='button button1'>Login</button></td></tr>
                </table>                
            </form>
        </center>
        
    )

}

export default Login;